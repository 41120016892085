import { useEffect, useState } from 'react';
import { Calendar } from '@/model/calendar/calendar';
import { selectApi } from '@/api';

export default function SingleCalendarTitle({ calendarSlug }: { calendarSlug: string }) {
  const [calendar, setCalendar] = useState<Calendar>();

  useEffect(() => {
    selectApi.getCalendarBySlug(calendarSlug).then(setCalendar);
  }, [calendarSlug]);

  return <>{calendar?.name}</>;
}
