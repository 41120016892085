import dayjs, { Dayjs } from 'dayjs';
import { useContext } from 'react';
import { AppContext } from '@/model/app-context';
import { Context } from '@/App';

export default function DateFormat({
  format,
  date,
}: {
  format: string;
  date: string | Date | Dayjs | undefined;
}) {
  const context = useContext<AppContext>(Context);

  return (
    <>
      {dayjs(date)
        .tz(
          // eslint-disable-next-line
          context.localPreferences.timezoneType == 'explicit'
            ? context.localPreferences.timezone
            : undefined,
        )
        .format(format)}
    </>
  );
}
