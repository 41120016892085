import { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';

import { NewsEvent } from '@/model/calendar/news-event';
import { Permissions } from '@/model/permissions';
import useTimer from '@/hooks/use-timer';
import DateFormat from '@/components/shared/date-format';
import CountryFlag from '@/components/shared/country-flag';
import EventBadges from '@/components/shared/event-badges';
import RequirePermissions from '@/components/shared/require-permissions';
import InstantAnswersRowEntry from '@/components/data-calendar/rows/instant-answers-row-entry';
import AuctionRowEntry from '@/components/data-calendar/rows/auction-row-entry';
import DataRowEntry from '@/components/data-calendar/rows/data-row-entry';
import { CoverageBadges } from '@/components/data-calendar/coverage/coverage-badges';

import calendarStyles from '@/components/data-calendar/list/calendar-daily-view.module.scss';

interface CalendarDailyViewProps {
  calendar?: NewsEvent[];
  setEvent: Function;
}

function FlagAndRating({ event }: { event: NewsEvent }) {
  return (
    <>
      <CountryFlag country={event.country} width={26} height={13} />
      <div className={'small'}>
        <EventBadges event={event} />
      </div>
    </>
  );
}

export default function CalendarDailyView({ calendar, setEvent }: CalendarDailyViewProps) {
  let [nextDate, setNextDate] = useState<Dayjs | null>(null);

  const refreshNextDate = () => {
    let found = false;

    calendar?.forEach(value => {
      let currentTime = dayjs(dayjs());

      let today = currentTime.format('YYYY-MM-DD');
      let eventDate = dayjs(value.date);

      if (eventDate.format('YYYY-MM-DD') === today) {
        if (!found) {
          if (eventDate.add(30, 'seconds').isAfter(currentTime)) {
            found = true;
            setNextDate(eventDate);
          }
        }
      }
    });
  };

  useTimer(
    30_000,
    () => {
      refreshNextDate();
    },
    true,
  );

  return (
    <>
      <table className={'table table-sm text-container-sm-start container-responsive-text-sm'}>
        <thead className={calendarStyles.stickyHeader}>
          <tr className={'text-text-dark bg-quaternary'}>
            <th className={'text-text-dark bg-quaternary'}>Time</th>
            <th className={'text-text-dark bg-quaternary'}></th>
            <th className={'text-text-dark bg-quaternary'}>Figure</th>
            <th className={'text-text-dark bg-quaternary'}></th>
            <th className={'text-text-dark bg-quaternary'}>Fcst</th>
            <th className={'fw-bold  bg-quaternary'}>Act</th>
            <th className={'text-text-dark bg-quaternary'}>Diff</th>
            <th className={'text-text-dark bg-quaternary'}>
              Prev<span className={'fw-bold text-body'}> / Rev</span>{' '}
            </th>
          </tr>
        </thead>

        {calendar?.map((event, index) => {
          let secondaryEntries = [];
          let rows: JSX.Element[] = [];

          event.auction &&
            event.auction.auctionProducts.forEach(value => {
              rows.push(<AuctionRowEntry auctionProduct={value} />);
            });

          event.dataSeriesEntries &&
            event.dataSeriesEntries.forEach(value => {
              if (value.isPreviousRevision) return;

              rows.push(
                <DataRowEntry
                  dataSeriesEntry={value}
                  date={event.date}
                  reportingPeriod={event.reportingPeriod}
                  isNext={nextDate?.isSame(dayjs(event.date)) || false}
                />,
              );
            });

          event.instantAnswers &&
            event.instantAnswers.forEach(value => {
              rows.push(<InstantAnswersRowEntry instantAnswers={value} />);
            });

          for (let i = 1; i < rows.length; i++) {
            secondaryEntries.push(
              <tr key={'row-' + event.id + '-' + i + '-' + index} onClick={() => setEvent(event)}>
                {rows[i]}
              </tr>,
            );
          }

          //fallback in case of no other rows
          if (rows.length === 0) {
            rows.push(
              <>
                <td colSpan={6}>{event.title}</td>
              </>,
            );
          }

          return (
            <tbody key={'cal-row-' + index} className={calendarStyles.dayTable}>
              <tr className={'data-calendar-top-row'} onClick={() => setEvent(event)}>
                <td rowSpan={rows.length} className={'head-column'} style={{ width: '5rem' }}>
                  <div className={'text-muted'}>
                    <div>
                      {' '}
                      <DateFormat format={'h:mm a'} date={event.date} />
                    </div>

                    <span className={'d-container-inline d-container-sm-none text-start'}>
                      <FlagAndRating event={event} />
                    </span>

                    <RequirePermissions deny={Permissions.EXCLUDE_NEWS}>
                      <CoverageBadges event={event} />
                    </RequirePermissions>
                  </div>
                </td>
                <td rowSpan={rows.length} style={{ maxWidth: '4em' }} className={'text-end'}>
                  <span className={'d-container-none d-container-sm-inline text-end'}>
                    <FlagAndRating event={event} />
                  </span>
                </td>
                {rows[0]}
              </tr>
              {secondaryEntries}
            </tbody>
          );
        })}
      </table>

      {calendar && calendar.length === 0 && (
        <div className={'alert alert-success text-center'}>
          <div>No releases for today.</div>
        </div>
      )}
    </>
  );
}
