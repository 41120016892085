import { NewsWidget } from '@/widgets/news-widget';
import { RouteObject } from 'react-router-dom';
import { convertWidgetToReactPath } from '@/widgets/widget-router/convert-widget-to-react-path';
import ReactWidgetRouter from '@/widgets/widget-router/react-widget-router';

export function convertWidgetToReactRoute(widget: NewsWidget): RouteObject[] {
  return [
    {
      path: widget.id,
      element: (
        <div className={'p-2 vh-100'}>
          <ReactWidgetRouter widget={widget} />
        </div>
      ),
      children: widget.routes.map(value => {
        return {
          ...value,
          path: convertWidgetToReactPath(widget.id, value.path),
        } as RouteObject;
      }),
    },
  ];
}
