import { createContext, useEffect, useState } from 'react';
import { createBrowserRouter, Navigate, RouteObject, RouterProvider } from 'react-router-dom';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import dayjs from 'dayjs';

import '@/index.scss';
import AuthProvider from '@/components/auth/auth-provider';
import LoginPage from '@/pages/auth/login-page';
import DockPage from '@/pages/dock-page';
import { AppContext } from '@/model/app-context';
import TokenExchangePage from '@/pages/auth/token-exchange-page';
import CalendarProvider, { CalendarContext } from '@/components/shared/calendar-provider';
import TestEnvIndicator from '@/components/shared/test-env-indicator';
import DashboardPage from '@/pages/dashboard-page';
import Auth0Page from '@/pages/auth/auth0-page';
import { Auth0Provider } from '@auth0/auth0-react';
import { convertWidgetToReactRoute } from '@/widgets/widget-router/convert-widget-to-react-route';
import useWidgetFactory from '@/hooks/use-widget-factory';
import ConnectionIndicators from '@/components/shared/connection-indicators';
import { convertWidgetFactoryToReactRoute } from '@/widgets/widget-router/convert-widget-factory-to-react-route';
import { localPreferencesService, themeService } from '@/services';
import PdfViewer, { ImageViewer } from '@/components/shared/pdf-viewer';
import { NotificationProvider } from '@/widgets/notifications/use-widget-notifications';
import { defaultPreferences } from '@/services/local-preferences-service';
import { LocalPreferences } from '@/model/preferences/local-preferences';

import 'react-toastify/dist/ReactToastify.css';
import ArticleViewPage from '@/pages/news/article-view-page';
import { articleApi } from '@/api';
import ToastsWrapper from '@/components/shared/toasts/toasts-wrapper';
import ReactGA from 'react-ga4';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);
dayjs.extend(relativeTime);

const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN || '';
const auth0Client = process.env.REACT_APP_AUTH0_CLIENT_ID || '';

if (process.env.REACT_APP_GA_MEASUREMENT_ID) {
  ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
}

setTimeout(() => {
  window.location.reload();
}, 86_400_000);

let perHostDefaultPage =
  process.env[
    `REACT_APP_DEFAULT_PATH_${window.location.hostname.toUpperCase().replaceAll('.', '_')}`
  ];
export const Context = createContext<AppContext>({
  showMenu: true,
  localPreferences: defaultPreferences(),
});

function App() {
  const [loaded, setLoaded] = useState<boolean>();
  const [preferences, setPreferences] = useState<LocalPreferences>();

  const widgetFactory = useWidgetFactory();

  useEffect(() => {
    localPreferencesService.getLocalPreferences().then((value: any) => {
      if (value.timezoneType === 'explicit') dayjs.tz.setDefault(value.timezone);
      else dayjs.tz.setDefault();

      if (value) themeService.setThemeBasedOnUserPreferences(value);

      setLoaded(true);
      setPreferences(value);
    });
  }, []);

  useEffect(() => {
    const timeout = setInterval(() => {
      localPreferencesService.getLocalPreferences().then(value => {
        if (!preferences) return;

        if (dayjs(preferences.lastUpdated).isBefore(dayjs(value.lastUpdated))) {
          console.warn('Updating local preferences');
          if (value) themeService.setThemeBasedOnUserPreferences(value);
          setPreferences(value);
        }
      });
    }, 5000);

    return () => {
      clearInterval(timeout);
    };
  }, [preferences]);

  if (!loaded || !preferences) return <></>;

  let widgetRoutes: RouteObject[] = [];

  widgetFactory.getStaticWidgetList().forEach(value => {
    widgetRoutes = [...widgetRoutes, ...convertWidgetToReactRoute(value)];
  });

  widgetFactory.getDynamicWidgetFactories().forEach(value => {
    widgetRoutes = [...widgetRoutes, ...convertWidgetFactoryToReactRoute(value)];
  });

  const router = createBrowserRouter([
    {
      path: '/',
      element: perHostDefaultPage ? (
        <Navigate to={perHostDefaultPage} />
      ) : (
        <CalendarProvider>
          <CalendarContext.Consumer>
            {value => <Navigate to={`/calendar/${value?.view}/${value?.date}`} />}
          </CalendarContext.Consumer>
        </CalendarProvider>
      ),
    },
    {
      path: '/login',
      element: <LoginPage />,
    },
    {
      path: '/article',
      element: <AuthProvider />,
      shouldRevalidate: a => {
        console.log(a);
        return false;
      },
      children: [
        {
          path: '/article/:articleId',
          element: <ArticleViewPage />,
          loader: async ({ params }) => {
            try {
              return await articleApi.getArticle(params.articleId || '');
            } catch (e) {
              return e;
            }
          },
        },
      ],
    },

    {
      path: '/auth0',
      element: <Auth0Page />,
    },
    {
      path: '/token',
      element: <TokenExchangePage />,
    },
    {
      path: '/token/:token',
      element: <TokenExchangePage />,
    },
    {
      path: '/dock',
      element: <AuthProvider />,
      shouldRevalidate: a => {
        console.log(a);
        return false;
      },
      children: [
        {
          path: '/dock',
          element: <DockPage />,
        },
      ],
    },
    {
      path: '/dashboard',
      element: (
        <ConnectionIndicators>
          <AuthProvider />
        </ConnectionIndicators>
      ),
      shouldRevalidate: a => {
        console.log(a);
        return false;
      },
      children: [
        {
          path: '/dashboard',
          element: <DashboardPage />,
        },
      ],
    },
    ...widgetRoutes.map(
      value =>
        ({
          path: value.path,
          element: (
            <ConnectionIndicators>
              <AuthProvider />
            </ConnectionIndicators>
          ),
          children: [value],
        }) as RouteObject,
    ),
  ]);

  return (
    <TestEnvIndicator>
      <div className="App">
        <NotificationProvider>
          <Auth0Provider
            domain={auth0Domain}
            clientId={auth0Client}
            authorizationParams={{
              redirect_uri: `${window.location.origin}/auth0`,
            }}
          >
            <Context.Provider value={{ showMenu: true, localPreferences: preferences }}>
              <RouterProvider router={router} />
              <ImageViewer />
              <PdfViewer />
            </Context.Provider>
            <ToastsWrapper />
          </Auth0Provider>
        </NotificationProvider>
      </div>
    </TestEnvIndicator>
  );
}

export default App;
