import { WidgetNotification } from '@/widgets/notifications/widget-notification';
import { Notification } from '@/widgets/notifications/notification';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { WidgetContainer } from '@/widgets/widget-container';
import { WidgetContainerContext } from '@/widgets/widget-container/widget-container-context';
import { analyticsService, messageService } from '@/services';
import { MessageServiceConstants } from '@/services/messaging/message-service-constants';
import { AnalyticsEvent } from '@/services/analytics/analytics-event';

interface WidgetNotificationService {
  sendNotification(notification: Notification): void;
  notifications: WidgetNotification[];
  setNotificationSoundOn(b: boolean): void;
  notificationSoundOn: boolean;
  acknowledgeNotification(notification: WidgetNotification): void;
  clearNotificationsByWidgetId(id: string): void;
}

const alert = new Audio('/sounds/level-up.mp3');
const shutter = new Audio('/sounds/shutter.mp3');

const savedNotifications = JSON.parse(
  localStorage.getItem('myMNI.notifications') || '[]',
) as WidgetNotification[];
const savedNotificationSound = JSON.parse(
  localStorage.getItem('myMNI.notificationSound') || 'true',
) as boolean;
interface NotificationState {
  notifications: WidgetNotification[];
  setNotifications(w: WidgetNotification[]): void;
  notificationSoundOn: boolean;
  setNotificationSoundOn(b: boolean): void;
}

const NotificationContext = createContext<NotificationState>({
  notifications: [],
  setNotifications(w: WidgetNotification[]) {},
  notificationSoundOn: false,
  setNotificationSoundOn(b: boolean) {},
});

export function NotificationProvider({ children }: React.PropsWithChildren<{}>) {
  const [notifications, setNotifications] = useState<WidgetNotification[]>(savedNotifications);
  const [notificationSoundOn, setNotificationSoundOn] = useState<boolean>(savedNotificationSound);

  useEffect(() => {
    localStorage.setItem('myMNI.notifications', JSON.stringify(notifications));
  }, [notifications]);

  useEffect(() => {
    localStorage.setItem('myMNI.notificationSound', JSON.stringify(notificationSoundOn));
  }, [notificationSoundOn]);

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        setNotifications,
        notificationSoundOn,
        setNotificationSoundOn,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
}

export default function useWidgetNotifications(): WidgetNotificationService {
  const { widgetId } = useContext<WidgetContainer>(WidgetContainerContext);

  const { notifications, notificationSoundOn, setNotificationSoundOn, setNotifications } =
    useContext<NotificationState>(NotificationContext);

  const addNotification = (notification: WidgetNotification) => {
    setTimeout(() => {
      let found = false;

      notifications
        .filter(
          // eslint-disable-next-line
          value => value.notification.correlationId == notification.notification.correlationId,
        )
        .forEach(value => {
          found = true;
          notification.widgetIds.forEach(id => {
            if (!value.widgetIds.includes(id)) value.widgetIds.push(id);
          });
        });

      if (found) {
        setNotifications([...notifications]);
        return;
      }

      messageService.publish<WidgetNotification>(
        MessageServiceConstants.WIDGET_NOTIFICATION_RECEIVED,
        notification,
      );

      notifications.unshift(notification);

      if (notifications.length > 20) notifications.pop();

      if (notificationSoundOn) {
        analyticsService.recordAnalyticsEvent(AnalyticsEvent.ALERT_PLAYED, {
          notification: notification.notification.title,
        });

        alert.play().then(value => {
          console.log('Sound done');
        });
      }

      setNotifications([...notifications]);
    });
  };

  const acknowledgeNotification = (notification: WidgetNotification) => {
    analyticsService.recordAnalyticsEvent(AnalyticsEvent.NOTIFICATION_CLICKED, {
      notification: notification.notification.title,
    });

    shutter.play().then(value => {
      console.log('Sound done');
    });
    // eslint-disable-next-line
    setNotifications(notifications.filter(value => value != notification));

    messageService.publish<WidgetNotification>(
      MessageServiceConstants.WIDGET_NOTIFICATION_ACKNOWLEDGED,
      notification,
    );
  };

  const clearNotificationsByWidgetId = (id: string): void => {
    const filtered = notifications.filter(value => !value.widgetIds.includes(id));

    if (filtered.length < notifications.length) setNotifications(filtered);
  };

  return {
    sendNotification(notification: Notification): void {
      const widgetIds = [];

      if (widgetId) widgetIds.push(widgetId);

      addNotification({
        notification,
        widgetIds,
      });
    },
    notifications,
    notificationSoundOn,
    setNotificationSoundOn,
    acknowledgeNotification,
    clearNotificationsByWidgetId,
  };
}
