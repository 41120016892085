import { Section } from '@/model/news/section';
import axios from 'axios';

async function getSectionByCode(code: string): Promise<Section> {
  return axios.get<Section>(`/api/v1/news/section/${code}`).then(value => value.data as Section);
}

const sectionApi = {
  getSectionByCode,
};

export default sectionApi;
