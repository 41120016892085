import React, { useEffect, useRef, useState } from 'react';

export default function RemoveWhenHiddenDiv({
  className,
  children,
}: React.PropsWithChildren<{ className: string }>) {
  const ref = useRef<HTMLDivElement | null>(null);
  const [visible, setVisible] = useState<boolean>(true);

  function checkVisiblity() {
    setVisible(
      getComputedStyle(ref.current as HTMLDivElement)
        .getPropertyValue('display')
        // eslint-disable-next-line
        .toLowerCase() != 'none',
    );
  }

  useEffect(() => {
    window.addEventListener('resize', ev => {
      if (ref.current) checkVisiblity();
    });

    if (ref.current) checkVisiblity();
    // eslint-disable-next-line
  }, [ref.current]);

  return (
    <div ref={ref} className={className}>
      {visible && children}
    </div>
  );
}
