import dayjs from 'dayjs';

import { NewsEvent } from '@/model/calendar/news-event';

const GOOGLE_FORMAT = 'YYYYMMDD[T]HHmmss';
const OUTLOOK_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss';
const ICAL_FORMAT = 'YYYYMMDD[T]HHmmss[Z]';

function createGoogleLink(event: NewsEvent): string {
  const title = encodeURIComponent('MNI: ' + event.title);
  const details = encodeURIComponent(event.title + ': ' + window.location.href);
  const start = dayjs(event.date).format(GOOGLE_FORMAT);
  const end = dayjs(event.date).add(30, 'minute').format(GOOGLE_FORMAT);

  return `https://www.google.com/calendar/render?action=TEMPLATE&text=${title}&details=${details}&dates=${start}/${end}`;
}

function createOutlookLink(event: NewsEvent): string {
  const title = encodeURIComponent('MNI: ' + event.title);
  const details = encodeURIComponent(event.title + ': ' + window.location.href);
  const start = dayjs(event.date).format(OUTLOOK_FORMAT);
  const end = dayjs(event.date).add(30, 'minute').format(OUTLOOK_FORMAT);
  return `https://outlook.live.com/calendar/0/deeplink/compose?path=/calendar/action/compose&rru=addevent&startdt=${start}&enddt=${end}&subject=${title}&body=${details}`;
}

function createOffice365Link(event: NewsEvent): string {
  const title = encodeURIComponent('MNI: ' + event.title);
  const details = encodeURIComponent(event.title + ': ' + window.location.href);
  const start = dayjs(event.date).format(OUTLOOK_FORMAT);
  const end = dayjs(event.date).add(30, 'minute').format(OUTLOOK_FORMAT);
  return `https://outlook.office.com/calendar/0/deeplink/compose?path=/calendar/action/compose&rru=addevent&startdt=${start}&enddt=${end}&subject=${title}&body=${details}`;
}

function createICalDownloadLink(event: NewsEvent): string {
  const title = encodeURIComponent('MNI: ' + event.title);
  const details = encodeURIComponent(event.title + ': ' + window.location.href);
  const now = dayjs().utc().format(ICAL_FORMAT);
  const start = dayjs(event.date).utc().format(ICAL_FORMAT);
  const end = dayjs(event.date).utc().add(30, 'minute').format(ICAL_FORMAT);

  return `data:text/ics,BEGIN:VCALENDAR
VERSION:2.0
CALSCALE:GREGORIAN
BEGIN:VEVENT
DTSTAMP:${now}
UID:mni-event-${event.id}
DTSTART:${start}
DTEND:${end}
SUMMARY:${title}
URL:${window.location.href}
DESCRIPTION:${details}
END:VEVENT
END:VCALENDAR`;
}
// eslint-disable-next-line
export default {
  createICalDownloadLink,
  createOffice365Link,
  createOutlookLink,
  createGoogleLink,
};
