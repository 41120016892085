import { FormSelect } from 'react-bootstrap';
import { NewsWidget } from '@/widgets/news-widget';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import LocalWidgetRouter from '@/widgets/widget-router/local-widget-router';
import useWidgetFactory from '@/hooks/use-widget-factory';
import HasWidgetPermissions from '@/widgets/widget-permissions/has-widget-permissions';
import DefaultWidgetContainerContext from '@/widgets/widget-container/default-widget-container-context';
import ReactGA from 'react-ga4';

export default function SingleWidgetHost({ headerControls }: { headerControls: JSX.Element }) {
  const widgetFactory = useWidgetFactory();

  const inactiveWidgets = useMemo(() => {
    return widgetFactory.getStaticWidgetList();
    // eslint-disable-next-line
  }, []);
  const getDefaultWidget = () => {
    const allWidgets = [...inactiveWidgets];

    const widgetId = localStorage.getItem('mobile-widget');
    if (widgetId) {
      const savedWidget = widgetFactory.getWidgetById(widgetId);

      if (savedWidget) return savedWidget;
    }

    return allWidgets[0];
  };

  const [selectedWidget, setSelectedWidget] = useState<NewsWidget>(getDefaultWidget());

  const selectWidget = (event: ChangeEvent<HTMLSelectElement>) => {
    // eslint-disable-next-line
    const widget = inactiveWidgets.find(value => value.id == event.target.value);

    if (widget) setSelectedWidget(widget);
  };

  useEffect(() => {
    console.log('selected widget', selectedWidget.id);
    localStorage.setItem('mobile-widget', selectedWidget.id);
  }, [selectedWidget]);

  useEffect(() => {
    if (selectedWidget.id) {
      ReactGA.send({
        hitType: 'pageview',
        page: `/mobile/~${selectedWidget.id}`,
        title: selectedWidget.defaultTitle,
      });
    }
  }, [selectedWidget]);

  return (
    <div className={'d-flex h-100 flex-column p-1'}>
      <div className={'d-flex m-1'}>
        <div className={'flex-grow-1 flex-shrink-1 me-1'}>
          <FormSelect
            size={'sm'}
            className={'mb-1'}
            onChange={selectWidget}
            value={selectedWidget.id}
          >
            {inactiveWidgets.map(value => (
              <option value={value.id} key={value.id + 'sel'}>
                {value.defaultTitle}
              </option>
            ))}
          </FormSelect>
        </div>

        <span className={'flex-shrink-0 d-flex'}>{headerControls}</span>
      </div>

      <div className={'overflow-auto flex-grow-1 m-1'}>
        <DefaultWidgetContainerContext widget={selectedWidget}>
          <HasWidgetPermissions widget={selectedWidget}>
            <LocalWidgetRouter
              widget={selectedWidget}
              key={selectedWidget.id}
              onNavigate={s => {}}
            />
          </HasWidgetPermissions>
        </DefaultWidgetContainerContext>
      </div>
    </div>
  );
}
