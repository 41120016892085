import React, { useEffect } from 'react';
import { NewsWidget } from '../news-widget';
import { WidgetHost } from '../widget-host';
import { RouteObject } from 'react-router-dom';

function ChatMainView() {
  let loading = false;
  useEffect(() => {
    if (loading) return;
    // eslint-disable-next-line
    loading = true;

    const sdkScriptNode = document.createElement('script');
    sdkScriptNode.src = `https://develop2.symphony.com/embed/sdk.js`;
    sdkScriptNode.id = 'symphony-ecm-sdk';
    sdkScriptNode.setAttribute('render', 'explicit');
    sdkScriptNode.setAttribute('data-onload', 'renderEcp');

    document.body.appendChild(sdkScriptNode);
    (window as any).renderEcp = () => {
      (document as any).querySelector('.ecp').innerHTML = '';
      (window as any).symphony
        .render('ecp', {
          streamId: 'jgZm8jmrF3E/6tzVMUc8PX///nKfSSeWdA==',
          ecpLoginPopup: true,
          condensed: true,
          mode: 'dark',
          sound: false,
          symphonyLogo: false,
        })
        .then(() => {
          loading = false;
        })
        .catch(() => {
          loading = false;
        });
    };
  }, [(window as any).symphony]);

  return <div className="ecp" style={{ height: '100%' }}></div>;
}

export class ChatWidget implements NewsWidget {
  defaultTitle: string = 'Chat';
  readonly id = '/chat';

  async hasPermission(permissions: string[]) {
    return permissions.some(value => /include-feature:.*/.test(value));
  }

  shouldHideToUser(permissions: string[]): boolean {
    //by default should be false
    return !permissions.some(value => /include-feature:.*/.test(value));
  }

  initialize(host: WidgetHost) {}
  get headerPanel(): JSX.Element {
    return <>💬 Chat</>;
  }

  headerActions({ children }: { children?: React.ReactNode }): JSX.Element {
    return <>{children}</>;
  }

  get shortTitle(): JSX.Element {
    return <>💬</>;
  }

  get mainPanel(): JSX.Element {
    return <ChatMainView />;
  }

  get routes(): RouteObject[] {
    return [
      {
        path: `/chat`,
        element: <ChatMainView />,
      },
    ];
  }

  get defaultRoute(): string {
    return `/chat`;
  }
}
