import React, { useContext, useEffect, useRef, useState } from 'react';
import { GridLoader } from 'react-spinners';
import useSubscription from '@/hooks/use-subscription';
import { Article } from '@/model/article';
import theme from '@/index.module.scss';
import { articleApi, sectionApi } from '@/api';
import { MessageServiceConstants } from '@/services/messaging/message-service-constants';
import { Section } from '@/model/news/section';
import { WidgetContainer } from '@/widgets/widget-container';
import { WidgetContainerContext } from '@/widgets/widget-container/widget-container-context';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useWidgetNotifications from '@/widgets/notifications/use-widget-notifications';
import SectionFilters from '@/components/scrollers/section-filters';
import { TitleChangeState } from '@/widgets/widget-state/title-change-state';

interface ScrollerProps {
  section: string[];
  articles?: Article[];
  dark?: boolean;
  title?: JSX.Element;
  onArticlesChange: (articles?: Article[]) => void;
  onBatchLoad: (articles: Article[]) => void;
}

const pageSize = 10;
export default function InfiniScroller(props: React.PropsWithChildren<ScrollerProps>) {
  const [articles, setArticles] = useState<Article[]>([]);
  const [offset, setOffset] = useState<number>(0);
  // eslint-disable-next-line
  const [forbidden, setForbidden] = useState<boolean>(false);
  const [sections, setSections] = useState<Section[]>();
  const [includedSections, setIncludedSections] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const widgetContainer = useContext<WidgetContainer>(WidgetContainerContext);
  const { sendNotification } = useWidgetNotifications();

  const [hasNewArticles, setHasNewArticles] = useState<boolean>(false);
  const [scrollPosition, setScrollPosition] = useState<{
    height: number;
    scrollPosition: number;
    visibleHeight: number;
    scrollTop: number;
  }>();

  const scrollBoxRef = useRef<HTMLDivElement | null>(null);

  const fetchData = (offset: number, oldArticles: Article[]) => {
    setLoading(true);
    setOffset(offset);
    setArticles(oldArticles);

    if (includedSections.length < 1) return;

    if (!sections) return;

    articleApi
      .getArticlesBySection(
        // eslint-disable-next-line
        includedSections.filter(value => sections.find(s => s.code == value)),
        {
          page: Math.ceil(offset / pageSize),
          limit: pageSize,
        },
      )
      .then(
        value => {
          mergeArticles(oldArticles, value.content);
          props.onBatchLoad(value.content);

          setForbidden(false);
          setLoading(false);
        },
        reason => {
          setForbidden(true);
          setLoading(false);
        },
      );
  };

  const mergeArticles = (
    oldArticles: Article[],
    newArticles: Article[],
    append: boolean = true,
  ) => {
    let a = oldArticles;

    newArticles.forEach(article => {
      const i = a?.findIndex(value => value.uri === article.uri);

      if (i == null || i < 0) {
        a.unshift(article);

        if (!append) a.pop();
      } else {
        a[i] = article;
      }
    });

    a = a.sort((a1, b) => new Date(b.firstcreated).getTime() - new Date(a1.firstcreated).getTime());

    setArticles([...a]);
  };

  useSubscription<Article>(
    MessageServiceConstants.NEWS_ARTICLE_TOPIC,
    (article: Article, headers) => {
      const sections: string[] = JSON.parse(headers.sections);

      if (includedSections.some(value => sections.includes(value))) {
        mergeArticles(articles, [article], false);

        sendNotification({
          correlationId: `scroller-${article.uri}`,
          title: 'New Article',
          description: article.headline,
          durationMillis: 300_000,
          date: new Date(),
        });
      }
    },
  );

  useEffect(() => {
    setOffset(0);
    setArticles([]);
    setLoading(true);
    fetchData(offset, []);

    Promise.allSettled(props.section.map(sectionApi.getSectionByCode)).then(value => {
      setSections(
        value
          // eslint-disable-next-line
          .filter(r => r.status == 'fulfilled')

          .map(r => (r as PromiseFulfilledResult<Awaited<Section>>).value)
          .filter(s => s.flags.authorized),
      );
    });

    if (props.section.length <= 1) {
      setIncludedSections(props.section);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.section]);

  useEffect(() => {
    props.onArticlesChange(articles);
    // eslint-disable-next-line
  }, [articles]);

  useEffect(() => {
    fetchData(0, []);
    scrollToTop();
    // eslint-disable-next-line
  }, [includedSections]);

  useEffect(() => {
    if (sections && sections?.length === 1) {
      setIncludedSections(sections.map(value => value.code));
    }
  }, [sections]);

  useEffect(() => {
    if (articles.length > 0) {
      if (new Date().getTime() - new Date(articles[0].firstcreated).getTime() < 300_000) {
        setHasNewArticles(true);
      } else setHasNewArticles(false);
    }
  }, [articles]);

  const titleChange = (s?: string) => {
    console.error('Setting title:', s);
    widgetContainer.setState<TitleChangeState>({ title: s });
  };

  const loadIfNearBottom = (height: number, scrollPosition: number) => {
    if (loading) {
      console.log('Already loading....');
      return;
    }

    if (scrollPosition >= height - 100) {
      console.log('Loading from scroll event', articles.length);
      setLoading(true);
      fetchData(offset + pageSize, articles);
    }
  };

  useEffect(() => {
    if (scrollPosition) loadIfNearBottom(scrollPosition.height, scrollPosition.scrollPosition);
    // eslint-disable-next-line
  }, [scrollPosition]);

  const scrollToTop = () => {
    const current = scrollBoxRef.current;
    if (current) current.scrollTo({ top: 0 });
  };

  return (
    <div className={'container-element h-100 '}>
      <div className={'h-100 d-flex flex-column container-responsive-text '}>
        <div className={'d-flex justify-content-around flex-wrap'}>
          {sections && sections.length > 1 && (
            <SectionFilters
              sections={sections}
              onFilteredSectionsChange={setIncludedSections}
              onFilteredDataNameChange={titleChange}
            />
          )}
        </div>

        {!articles && (
          <div style={{ textAlign: 'center' }} className={'mt-5'}>
            <GridLoader color={theme.info} />
          </div>
        )}

        <div
          className={'flex-fill mt-3 overflow-auto '}
          ref={scrollBoxRef}
          onScroll={event => {
            const target = event.target as HTMLDivElement;

            const scrollHeight = target.scrollHeight;
            const scrollPosition = target.scrollTop + target.offsetHeight;
            // eslint-disable-next-line
            if (scrollPosition == scrollHeight) target.scrollTo({ top: target.scrollTop - 110 });

            setScrollPosition({
              scrollPosition,
              height: scrollHeight,
              visibleHeight: target.clientHeight,
              scrollTop: target.scrollTop,
            });
          }}
        >
          {props.children}

          <div style={{ textAlign: 'center' }} className={'mt-5'}>
            <GridLoader color={theme.info} />
          </div>

          {scrollPosition && scrollPosition.scrollTop > scrollPosition.visibleHeight && (
            <>
              <div className={'position-absolute bottom-0 start-0   '}>
                <div className={'bg-body-secondary '}>
                  {/*  eslint-disable-next-line   */}
                  <a
                    href={''}
                    onClick={event => {
                      event.stopPropagation();
                      event.preventDefault();
                      scrollToTop();
                    }}
                  >
                    Top ({articles.length} items) <FontAwesomeIcon icon={faArrowUp} />
                  </a>
                </div>
              </div>
              <div className={'position-absolute bottom-0 end-0'}>
                <div className={'bg-body-secondary'}>
                  {hasNewArticles && (
                    // eslint-disable-next-line
                    <a
                      href={''}
                      onClick={event => {
                        event.stopPropagation();
                        event.preventDefault();
                        scrollToTop();
                      }}
                    >
                      <FontAwesomeIcon icon={faArrowUp} /> New Article Available
                    </a>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
