import { useState, useEffect, useRef } from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import useWidgetFactory from '@/hooks/use-widget-factory';
import { NewsWidget } from '@/widgets/news-widget';
import { WidgetUpgradeBadge } from '@/widgets/widget-permissions/widget-upgrade-badge';
import { WidgetMenuEntry } from '@/widgets/news-widget-factory';
import { authService } from '@/services';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import styles from '@/components/dashboard/dashboard-add-item-control.module.css';

export default function DashboardAddItemControl({
  onAddWidget,
  activeWidgets,
}: {
  onAddWidget: (w: string) => void;
  activeWidgets: NewsWidget[];
}) {
  const widgetFactory = useWidgetFactory();
  const permissions = authService.getCredentials()?.body.permissions || [];
  const [openMenus, setOpenMenus] = useState<string[]>([]);

  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const toggleMenu = (key: string, parentKey: string = '') => {
    if (parentKey) {
      setOpenMenus(prev => {
        if (prev.includes(key)) {
          return prev.filter(k => k !== key);
        }
        const newMenus = prev.filter(k => !k.startsWith(`${parentKey}-`));
        return [...newMenus, key];
      });
    } else {
      setOpenMenus(prev => {
        if (prev.includes(key)) {
          return prev.filter(k => k !== key);
        } else {
          return [key];
        }
      });
    }
  };

  const isMenuOpen = (key: string) => openMenus.includes(key);

  function getDropdownButton(
    name: string | JSX.Element,
    items: WidgetMenuEntry[],
    key: string,
    parentKey: string = '',
    level: number = 0,
  ) {
    const menuKey = `fact-${key}`;
    const paddingRight = 60 - level * 20;

    return (
      <div key={menuKey}>
        <div
          className={`dropdown-item ${styles.menuItem}`}
          onClick={() => toggleMenu(menuKey, parentKey)}
          style={{ cursor: 'pointer', paddingRight: `${paddingRight}px` }}
        >
          {name}
          <span>
            {isMenuOpen(menuKey) ? (
              <FontAwesomeIcon icon={faChevronDown} />
            ) : (
              <FontAwesomeIcon icon={faChevronRight} />
            )}
          </span>
        </div>
        {isMenuOpen(menuKey) && (
          <div style={{ paddingLeft: '15px' }}>
            {items.map((item, windex) => {
              if (item.url !== undefined) {
                return (
                  <Dropdown.Item
                    key={item.url + '-d-widget'}
                    onClick={() => onAddWidget(item.url || '')}
                    style={{ paddingRight: `${paddingRight - 20}px` }}
                  >
                    {item.name}
                  </Dropdown.Item>
                );
              } else {
                return getDropdownButton(
                  item.name,
                  item.children,
                  `${key}-${windex}`,
                  menuKey,
                  level + 1,
                );
              }
            })}
          </div>
        )}
      </div>
    );
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setOpenMenus([]);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <DropdownButton
      variant={'outline-secondary'}
      title={'Add Item'}
      drop="down-centered"
      size={'sm'}
      className={styles.dropdownButton}
      style={{ width: 'auto' }}
      ref={dropdownRef}
    >
      {widgetFactory
        .getStaticWidgetList()
        .filter(value => !activeWidgets.find(w => value.id === w.id))
        .filter(value => !(value.shouldHideToUser && value.shouldHideToUser(permissions) === true))
        .map(value => (
          <Dropdown.Item onClick={() => onAddWidget(value.id)} key={value.id + '-widget'}>
            {value.headerPanel as JSX.Element}{' '}
            <sup>
              <WidgetUpgradeBadge widget={value} />
            </sup>
          </Dropdown.Item>
        ))}

      <Dropdown.Divider />

      {widgetFactory
        .getDynamicWidgetFactories()
        .filter(value => value.getListableUrls().length > 0)
        .map((value, index) => {
          return getDropdownButton(value.headerPanel, value.getListableUrls(), `${index}`);
        })}
    </DropdownButton>
  );
}
