import { DataSeriesEntryForecast } from '@/model/calendar/data-series-entry-forecast';
import { LocalStorageService } from '@/services/storage/local-storage-service';

interface EntryForecast {
  series: number;
  date: string;
  forecast: DataSeriesEntryForecast;
}

const storageService = new LocalStorageService();

type ForecastMap = { [key: string]: EntryForecast };

const _key = 'forecasts';

async function setForecastForSeriesEntry(
  date: string,
  series: number,
  forecast: DataSeriesEntryForecast,
): Promise<void> {
  const forecasts = await getForecasts();

  forecasts[getKey(series, date)] = {
    series,
    date: date.toString(),
    forecast,
  };

  await storageService.saveValue(_key, forecasts);
}

async function getForecastForSeriesEntry(
  date: string,
  series: number,
): Promise<DataSeriesEntryForecast | undefined> {
  return (await getForecasts())[getKey(series, date)]?.forecast;
}

async function removeForecastForSeriesEntry(date: string, series: number): Promise<void> {
  const forecasts = await getForecasts();

  delete forecasts[getKey(series, date)];

  await storageService.saveValue(_key, forecasts);
}

async function getForecasts() {
  return (await storageService.getValue<ForecastMap>(_key)) || {};
}

function getKey(series: number, date: string) {
  return `${series}-${date}`;
}

const userForecastService = {
  setForecastForSeriesEntry,
  getForecastForSeriesEntry,
  removeForecastForSeriesEntry,
};

export default userForecastService;
