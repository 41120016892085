import React from 'react';
import MainwireScroller from '@/components/scrollers/mainwire/mainwire-scroller';
import { RouteObject } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRectangleList } from '@fortawesome/free-solid-svg-icons';
import { BaseScroller, BaseScrollerState } from '@/widgets/widgets/scrollers/base-scroller';
import { WidgetContainerContext } from '@/widgets/widget-container/widget-container-context';

export class MainwireScrollerWidget extends BaseScroller {
  constructor(id: string, section: string[], initialState: BaseScrollerState, _shortTitle: string) {
    super(`/bullets/${id}`, section, initialState, _shortTitle);
  }
  get headerPanel(): JSX.Element {
    return (
      <>
        <WidgetContainerContext.Consumer>
          {widgetContainer => (
            <div>
              <FontAwesomeIcon icon={faRectangleList} />{' '}
              {widgetContainer.getState!<BaseScrollerState>()?.title ?? this.defaultTitle}
            </div>
          )}
        </WidgetContainerContext.Consumer>
      </>
    );
  }

  headerActions({ children }: { children?: React.ReactNode }): JSX.Element {
    return <>{children}</>;
  }

  get routes(): RouteObject[] {
    return [
      {
        path: '',
        element: <MainwireScroller section={this.section} includedSections={this.section} />,
      },
    ];
  }

  get defaultRoute(): string {
    return '/';
  }
}
