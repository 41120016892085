import React, { useEffect, useState } from 'react';

import { DataSeriesEntry } from '@/model/calendar/data-series-entry';
import { DataSeriesEntryForecast } from '@/model/calendar/data-series-entry-forecast';
import { FormattingUtil } from '@/util/formatting-util';
import ForecastEditor from '@/components/shared/forecast-editor';

import styles from '@/components/data-calendar/rows/data-row-entry.module.css';
import { readerService } from '@/services';

interface DataRowEntryProps {
  dataSeriesEntry: DataSeriesEntry;
  date: string;
  reportingPeriod?: string;
  isNext: boolean;
}

export default function DataRowEntry({
  dataSeriesEntry,
  date,
  reportingPeriod,
  isNext,
}: DataRowEntryProps) {
  const getDiffClass = (diff: number | null) => {
    let diffClass: string | undefined;

    if (diff === null) diffClass = '';
    else if (diff > 0) diffClass = 'diff-positive';
    else diffClass = 'diff-negative';

    return diffClass;
  };

  const [diff, setDiff] = useState(dataSeriesEntry.diff());
  const [diffClass, setDiffClass] = useState(getDiffClass(diff));

  const refreshEntry = (forecast?: DataSeriesEntryForecast) => {
    if (!forecast) dataSeriesEntry.removeUserForecast();
    else dataSeriesEntry.addForecast(forecast);

    setDiff(dataSeriesEntry.diff());
    setDiffClass(getDiffClass(dataSeriesEntry.diff()));
  };

  useEffect(() => {
    setDiff(dataSeriesEntry.diff());
    setDiffClass(getDiffClass(dataSeriesEntry.diff()));
  }, [dataSeriesEntry, dataSeriesEntry.actual]);

  return (
    <React.Fragment>
      <td
        className={
          readerService.isReading(dataSeriesEntry.dataSeriesId, date)
            ? 'text-body-secondary'
            : 'text-text-dark'
        }
      >
        {dataSeriesEntry.display}
        <small className={'text-muted d-container-none d-container-sm-inline'}>
          {' '}
          {reportingPeriod}
        </small>
      </td>
      <td>
        <span className={'text-muted d-container-none d-container-sm-inline'}>
          {dataSeriesEntry.scale} {dataSeriesEntry.type}
        </span>
      </td>
      <td
        className={`${isNext ? 'border-start bg-quaternary' : ''}`}
        onClick={event => {
          event.preventDefault();
          event.stopPropagation();
        }}
      >
        <ForecastEditor
          entry={dataSeriesEntry}
          date={date}
          onChange={forecast => refreshEntry(forecast)}
        />
      </td>
      <td
        className={`${isNext ? 'bg-quaternary' : ''} ${
          !dataSeriesEntry.actual && !dataSeriesEntry.hasProvider && styles.noProvider
        }`}
        key={'actual-' + dataSeriesEntry}
      >
        {dataSeriesEntry.actual && (
          <strong>{FormattingUtil.formatNumber(dataSeriesEntry.actual)}</strong>
        )}
        {!dataSeriesEntry.actual && !dataSeriesEntry.hasProvider && (
          <div className={styles.noProvider} />
        )}
      </td>
      <td className={`${isNext ? 'bg-quaternary' : ''}`} key={'diff-' + diff + diffClass}>
        <span className={diffClass}>{FormattingUtil.formatNumber(diff)}</span>
      </td>
      <td className={`${isNext ? 'border-end bg-quaternary' : ''}`}>
        <span className={'text-text-dark'}>
          {FormattingUtil.formatNumber(dataSeriesEntry.previous)}
        </span>
        {dataSeriesEntry.hasPreviousRevision && (
          <span className={`text-body`}>
            {' '}
            / {FormattingUtil.formatNumber(dataSeriesEntry.previousRevisionEntry?.actual || '__')}
          </span>
        )}
      </td>
    </React.Fragment>
  );
}
