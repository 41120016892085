export interface ArticleMetadata {
  subjects: string[];
  keywords: string[];
}

const mapping: { [key: string]: ArticleMetadata } = {
  'mnidatatopics:inflation': {
    subjects: ['mni-section:551192114', 'mni-section:477130949', 'mni-section:480257795'],
    keywords: ['cpi', 'ppi'],
  },
};
// eslint-disable-next-line
export default {
  getConceptItemsFromDataTopics(topics: string[]): ArticleMetadata {
    const subjects = new Set<string>();
    const keywords = new Set<string>();

    topics.forEach(topic => {
      console.log(topic);
      if (mapping[topic]) {
        mapping[topic].subjects.forEach(value => subjects.add(value));
        mapping[topic].keywords.forEach(value => keywords.add(value.toLowerCase()));
      }
    });

    return {
      subjects: Array.from(subjects.values()),
      keywords: Array.from(keywords.values()),
    };
  },
};
