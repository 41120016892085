import { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import dayjs from 'dayjs';
import { faCalendarPlus, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Article } from '@/model/article';
import { DataTopic } from '@/model/data/data-topic';
import { Permissions } from '@/model/permissions';
import { NewsEvent, NewsEventUtil } from '@/model/calendar/news-event';
import { Observation } from '@/model/calendar/observation';
import { InstantAnswers } from '@/model/calendar/instant-answers';
import useSubscription from '@/hooks/use-subscription';
import CountryFlag from '@/components/shared/country-flag';
import UpgradePanel from '@/components/shared/upgrade-panel';
import DateFormat from '@/components/shared/date-format';
import RequirePermissions from '@/components/shared/require-permissions';
import EventBadges from '@/components/shared/event-badges';
import ArticleList from '@/components/articles/article-list';
import EventDetailsDataTable from '@/components/data-calendar/details/event-details-data-table';
import InstantAnswersTable from '@/components/data-calendar/details/instant-answers-table';
import RelatedArticles from '@/components/data-calendar/details/related-articles';
import { CoverageMap, EventCoverage } from '@/components/data-calendar/details/coverage-classes';
import { articleApi } from '@/api';
import { analyticsService, calendarLinkService, readerService } from '@/services';
import { MessageServiceConstants } from '@/services/messaging/message-service-constants';
import { AnalyticsEvent } from '@/services/analytics/analytics-event';

export default function CalendarEventDetails({
  newsEvent,
  onClose,
  onArticleClick,
}: {
  newsEvent: NewsEvent;
  onClose: Function;
  onArticleClick: (article: Article) => void;
}) {
  const topics: { [key: string]: DataTopic } = {};

  newsEvent.dataRelease?.series.forEach(value => {
    value.topics.forEach(topic => {
      topics[topic.qcode] = topic;
    });
  });

  let [coverageMap, setCoverageMap] = useState<CoverageMap>(new CoverageMap());
  let [event, setEvent] = useState<NewsEvent>(newsEvent);

  useEffect(
    () => {
      event.coverage?.forEach(value => {
        articleApi.getArticle(value.articleId).then(article => {
          setCoverageMap(coverageMap.addItem(new EventCoverage(value, article)));
        });
      });

      analyticsService.recordAnalyticsEvent(AnalyticsEvent.HSC_EVENT_CLICKED, {
        eventId: newsEvent.id,
        type: 'data-event',
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useSubscription<Observation[]>(MessageServiceConstants.SELECT_TOPIC, message => {
    message.forEach(value => NewsEventUtil.mergeObservations(event, value));
    setEvent({ ...event });
  });

  useSubscription<InstantAnswers[]>(MessageServiceConstants.INSTANT_ANSWERS_TOPIC, message => {
    message.forEach(value => NewsEventUtil.mergeInstantAnswers(event, value));
    setEvent({ ...event });
  });

  const readText = (text: string) => {
    readerService.readScheduledHeadingOr(text);
  };

  const scheduleRead = (id: string) => {
    if (id === '') readerService.scheduleReading();
    else {
      const dataSeriesEntry = event.dataSeriesEntries.find(value => `${value.dataSeriesId}` === id);

      if (!dataSeriesEntry) {
        readerService.scheduleReading();
        return;
      }

      readerService.scheduleReading({
        country: event.country,
        text: dataSeriesEntry.display,
        dataSeriesId: dataSeriesEntry.dataSeriesId,
        event: event.id,
        date: dayjs(event.date).toDate(),
      });
    }
  };

  const isScheduledToRead = (id: number): boolean => {
    return readerService.getDataSeriesReading()?.dataSeriesId === id;
  };

  return (
    <div className="container-element h-100 overflow-auto">
      <div className={'container-responsive-text'}>
        <div
          className={'sticky-top border-bottom pb-1 mb-2 '}
          style={{ backgroundColor: 'var(--bs-body-bg' }}
        >
          <div className={'d-flex align-items-start'}>
            <div className={'me-auto'}>
              <h4>
                <CountryFlag country={event.country} height={20} /> <EventBadges event={event} />{' '}
                {event.title}{' '}
              </h4>
              <div className={'text-muted'}>
                <span>
                  {' '}
                  <DateFormat format={'MMM D, h:mm A'} date={event.date} />{' '}
                </span>
              </div>
            </div>

            <div className={'text-end flex-shrink-0'}>
              <div>
                <span className={'d-inline-block'}>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="link"
                      id="dropdown-basic"
                      size="sm"
                      className={'link-warning'}
                    >
                      <FontAwesomeIcon icon={faCalendarPlus} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className={'border-warning'}>
                      <Dropdown.Item
                        href={calendarLinkService.createGoogleLink(event)}
                        target={'_blank'}
                      >
                        Add to Google Calendar
                      </Dropdown.Item>
                      <Dropdown.Item
                        href={calendarLinkService.createOutlookLink(event)}
                        target={'_blank'}
                      >
                        Add to Outlook Live Calendar
                      </Dropdown.Item>
                      <Dropdown.Item
                        href={calendarLinkService.createOffice365Link(event)}
                        target={'_blank'}
                      >
                        Add to Office 365 Calendar
                      </Dropdown.Item>
                      <Dropdown.Item
                        href={calendarLinkService.createICalDownloadLink(event)}
                        download={'event.ics'}
                      >
                        Download ICal File
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </span>
                <span>
                  <button
                    onClick={() => onClose()}
                    style={{ fontFamily: 'Inter' }}
                    className={'btn btn-sm btn-link link-error'}
                  >
                    <FontAwesomeIcon icon={faCircleXmark} />
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>

        <RequirePermissions deny={Permissions.EXCLUDE_NEWS}>
          {coverageMap
            ?.coverageTypes()
            .sort((a, b) => {
              if (a.display < b.display) return -1;
              else if (a.display < b.display) return 1;
              return 0;
            })
            .map(value => {
              return (
                <div className={'mb-3'} key={'coverage-' + value.name}>
                  <h5 className={'calendar-section-header'}>
                    <span className={'text-secondary'}> {value.display}</span> Coverage
                  </h5>

                  <ArticleList
                    id={`coverage-${value.name}`}
                    articles={coverageMap?.articlesByType(value.name)}
                    onArticleClick={onArticleClick}
                  />
                </div>
              );
            })}
        </RequirePermissions>

        {event.dataSeriesEntries && event.dataSeriesEntries.length > 0 && (
          <div className={'mb-3'}>
            <h5 className={'calendar-section-header fw-bold'}>
              <span className={'text-secondary'}>MNI</span> Data
            </h5>
            <EventDetailsDataTable
              dataSeriesEntries={event.dataSeriesEntries}
              date={newsEvent.date}
            />
            {dayjs(event.date).isAfter(dayjs()) && (
              <>
                <h6 className={'calendar-section-header fw-bold'}>Data Reader</h6>
                <p className={'text-muted'}>
                  This will read an incoming data figure out loud as it is received.
                </p>

                <div className="input-group ">
                  <select
                    className={'form-control form-control-sm'}
                    onChange={event1 => scheduleRead(event1.target.value)}
                  >
                    <option value={''}> - No Reading -</option>
                    {event.dataSeriesEntries.map(value => (
                      <option
                        key={value.dataSeriesId}
                        selected={isScheduledToRead(value.dataSeriesId)}
                        value={value.dataSeriesId}
                      >
                        {value.display}
                      </option>
                    ))}
                  </select>
                  <button
                    style={{ fontFamily: 'Inter' }}
                    className={'btn btn-sm btn-outline-success bg-quaternary'}
                    onClick={() => readText('No Reading Scheduled')}
                  >
                    Preview
                  </button>
                </div>
              </>
            )}
          </div>
        )}

        {event.instantAnswers && event.instantAnswers.length > 0 && (
          <div className={'mb-3'}>
            <h5 className={'calendar-section-header fw-bold'}>
              <span className={'text-secondary'}>MNI</span> Instant Answers
            </h5>

            {event.instantAnswers.map(ia => {
              let iaDisplay = () => {
                switch (ia.state) {
                  case 'NOT_AUTHORIZED':
                    return <UpgradePanel contentName={ia.display} />;
                  case 'AWAITING_DEFINITION':
                    return (
                      <div className={'alert alert-warning text-center'}>
                        <p>
                          <strong>
                            <em>{ia.display}</em> Questions will be available 24 hours before event
                          </strong>
                        </p>
                        <p>
                          Our reporters are formulating our instant answers broadcast based on
                          current events and conditions.
                        </p>
                        <p>
                          <em>Check back 24 hours before the event!</em>
                        </p>
                      </div>
                    );
                  default:
                    return (
                      <>
                        <h6>{ia.display}</h6>
                        <InstantAnswersTable instantAnswers={ia} />
                      </>
                    );
                }
              };

              return <div key={`ia-${ia.display}`}>{iaDisplay()}</div>;
            })}
          </div>
        )}

        <RequirePermissions allow={Permissions.BETA_ACCESS}>
          <div>
            <h5 className={'calendar-section-header'}>
              <span className={'text-secondary'}>MNI</span> Related Articles
            </h5>
            <RelatedArticles event={event} onArticleClick={onArticleClick} />
          </div>
        </RequirePermissions>
      </div>
    </div>
  );
}
