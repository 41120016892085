import { LocalPreferences } from '@/model/preferences/local-preferences';
// eslint-disable-next-line
export default {
  setThemeBasedOnUserPreferences(localPreferences: LocalPreferences) {
    const root = document.getElementById('html-root-element');

    if (root) {
      if (localPreferences.contrastingColors)
        root.setAttribute('data-diff-theme', localPreferences.contrastingColors);
      if (localPreferences.theme) root.setAttribute('data-bs-theme', localPreferences.theme);

      root.setAttribute('data-flag-display', localPreferences.showFlags ? 'images' : 'text');
    }
  },
};
