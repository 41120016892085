import { Navigate, Outlet } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ApiError } from '@/services/errors/api-error';
import useSubscription from '@/hooks/use-subscription';
import { authService } from '@/services';
import { MessageServiceConstants } from '@/services/messaging/message-service-constants';
import { useCookies } from 'react-cookie';

type Props = {
  children?: JSX.Element | JSX.Element[];
};

let perHostLoginPage =
  process.env[
    `REACT_APP_LOGIN_PATH_${window.location.hostname.toUpperCase().replaceAll('.', '_')}`
  ];

const loginPage = perHostLoginPage
  ? perHostLoginPage
  : (process.env.REACT_APP_LOGIN_PATH as string);

export default function AuthProvider({ children }: Props) {
  const [token, setToken] = useState<string>();
  const [loading, setLoading] = useState(true);

  const [cookies] = useCookies();

  useEffect(() => {
    authService.getAccessToken().then((value: any) => {
      if (value?.access_token) {
        if (value.source === 'auth0' && cookies['permissions'] === undefined) {
          authService.logout();
        } else {
          setToken(value?.access_token);
        }
      }
      setLoading(false);
    });
    // eslint-disable-next-line
  }, []);

  useSubscription<ApiError>(MessageServiceConstants.API_RESPONSE_ERRORS, (message: any) => {
    console.warn('API ERROR', message);

    if (!authService.tokenValid()) {
      console.warn('Logout', message);

      authService.logout().then(() => {
        setToken(undefined);
      });
    }
  });

  if (loading) return <></>;

  if (token) return <Outlet />;
  else return <Navigate to={loginPage} />;
}
