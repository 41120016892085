import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight, faCircleXmark } from '@fortawesome/free-solid-svg-icons';

import Menu from '@/components/shared/menu';

export default function PageControl({
  offset,
  onChange,
  title,
  dark = true,
  onClose,
}: {
  title?: JSX.Element;
  offset: number;
  onChange: (offset: number) => void;
  dark?: boolean;
  onClose?: () => void;
}) {
  const first = () => {
    onChange(0);
  };

  const previous = () => {
    let o = (offset || 0) - 1;

    if (o < 0) o = 0;

    onChange(o);
  };

  const next = () => {
    let o = (offset || 0) + 1;

    onChange(o);
  };
  return (
    <div className={'d-flex justify-content-between pt-1 pb-1 flex-grow-0 align-items-center'}>
      <span className={'me-auto'}>
        <button
          className={`btn btn-sm ${dark ? 'chevron' : 'chevron-light'} kk  btn-menu me-1`}
          style={{ fontFamily: 'Inter' }}
          onClick={first}
          disabled={offset === 0}
        >
          <FontAwesomeIcon icon={faCaretLeft} />
          <FontAwesomeIcon icon={faCaretLeft} />
        </button>
        <button
          className={`btn btn-sm ${dark ? 'chevron' : 'chevron-light'} kk btn-menu me-1`}
          onClick={previous}
          disabled={offset === 0}
        >
          <FontAwesomeIcon icon={faCaretLeft} />
        </button>
      </span>
      <span>
        {title && <>{title} - </>}
        <span className={`${dark ? 'text-muted' : ''} small`}>Page {(offset || 0) + 1}</span>
      </span>

      <button
        className={`btn btn-sm  ${dark ? 'chevron' : 'chevron-light'} btn-menu ms-auto`}
        onClick={next}
      >
        <FontAwesomeIcon icon={faCaretRight} />
      </button>

      {onClose && (
        <button className={'btn btn-link link-danger btn-sm'} onClick={onClose}>
          <FontAwesomeIcon icon={faCircleXmark} />
        </button>
      )}

      <span>
        <Menu />
      </span>
    </div>
  );
}
