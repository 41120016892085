import { NewsWidget } from '@/widgets/news-widget';
import React from 'react';
import { useWidgetPermission } from '@/hooks/use-widget-permission';
import UpgradePanel from '@/components/shared/upgrade-panel';

export default function HasWidgetPermissions({
  widget,
  children,
}: React.PropsWithChildren<{ widget: NewsWidget }>) {
  const { authorized, loadingPermission } = useWidgetPermission(widget);

  return (
    <>
      {!loadingPermission && (
        <>
          {!authorized && <UpgradePanel contentName={widget.defaultTitle} />}
          {authorized && <>{children}</>}
        </>
      )}
    </>
  );
}
