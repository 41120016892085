import { useEffect, useState } from 'react';
import { Calendar } from '@/model/calendar/calendar';
import { selectApi } from '@/api';
import Events from '@/components/calendars/events';

export default function SingleCalendar({ calendarSlug }: { calendarSlug: string }) {
  const [calendar, setCalendar] = useState<Calendar>();

  useEffect(() => {
    selectApi.getCalendarBySlug(calendarSlug).then(setCalendar);
  }, [calendarSlug]);

  return (
    <div className={'container-element h-100 '}>
      <div className={' d-flex flex-column h-100 '}>
        {calendar && <Events calendar={calendar} />}
      </div>
    </div>
  );
}
