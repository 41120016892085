import { useState } from 'react';
import dayjs from 'dayjs';
import { Article } from '@/model/article';
import AgedDateFormat from '@/components/shared/aged-date-format';
import TaggedHeadline from '@/components/shared/tagged-headline';
import styles from '@/components/articles/article-view.module.scss';
import articleListStyles from '@/components/articles/article-list.module.scss';
import { opinionsApi } from '@/api';
import ArticleBodyDisplay from '@/components/articles/article-body-display';
import InfiniScroller from '@/components/scrollers/infini-scroller';
import PinButton from '@/components/pins/pin-button';
import BookmarkButton from '@/components/bookmarks/bookmark-button';
import { OpinionMap } from '@/model/opinions/opinion-map';

interface BulletListProps {
  section: string[];
  articles?: Article[];

  onArticleClick: Function;
  dark?: boolean;
  title?: JSX.Element;
}

export default function BulletList(props: BulletListProps) {
  const [articles, setArticles] = useState<Article[]>();
  const [opinions, setOpinions] = useState<OpinionMap>({});

  const loadOpinionsOfBatch = (a: Article[]) => {
    opinionsApi.getOpinionsOfArticles(...a.map(value => value.uri)).then(value => {
      for (let valueKey in value) {
        opinions[valueKey] = value[valueKey];
      }

      setOpinions({ ...opinions });
    });
  };

  const flash = (index: number, date: string) => {
    if (index === 0 && dayjs(date).isAfter(dayjs().subtract(5, 'minute')))
      return articleListStyles.flashArticle;

    return '';
  };

  return (
    <InfiniScroller
      section={props.section}
      onArticlesChange={setArticles}
      onBatchLoad={loadOpinionsOfBatch}
    >
      {articles?.map((value, index) => {
        return (
          <div key={'b-' + value.uri} className={`${flash(index, value.firstcreated)}`}>
            <div className={'d-flex justify-content-between align-items-baseline mb-1'}>
              <div>
                <AgedDateFormat
                  format={'h:mm a[:]'}
                  fullFormat={'MMM D, h:mm a[:]'}
                  date={value.firstcreated}
                  scale={10}
                />{' '}
                <strong>
                  <TaggedHeadline headline={value.headline} />
                </strong>
              </div>
              <div className={'flex-shrink-0'}>
                <PinButton article={value} />
                <BookmarkButton article={value} providedOpinions={opinions} />
              </div>
            </div>
            <div>
              <div className={'text-text-dark ' + styles.article}>
                <ArticleBodyDisplay xhtml={value.body_xhtml} />
              </div>
            </div>
            <div className={'mb-2 small'}></div>
          </div>
        );
      })}
    </InfiniScroller>
  );
}
