import { Link, LinkProps } from 'react-router-dom';
import * as React from 'react';
import { useContext } from 'react';
import {
  LocalWidgetRouterContext,
  LocalWidgetRouterState,
} from '@/widgets/widget-router/local-widget-router';

export default function WidgetLink({
  to,
  children,
  className,
}: LinkProps & React.RefAttributes<HTMLAnchorElement> & { to: string }) {
  const context = useContext<LocalWidgetRouterState>(LocalWidgetRouterContext);

  if (context.useLocal) {
    return (
      // eslint-disable-next-line
      <a
        onClick={event => {
          event.stopPropagation();
          event.preventDefault();
          context.navigate(to);
        }}
        href={''}
        className={className}
      >
        {children}
      </a>
    );
  }

  return (
    <Link to={to} className={className}>
      {children}
    </Link>
  );
}
