export type BreakpointSize = 'lg' | 'md' | 'sm' | 'xs' | 'xxs';
export const breakpoints: { [k: string]: number } = {
  lg: 1800,
  md: 1200,
  sm: 900,
  xs: 100,
  xxs: 0,
};

// these should ALWAYS BE MULTIPLES OF 4!
export const cols: { [k: string]: number } = { lg: 16, md: 12, sm: 8, xs: 4, xxs: 4 };

export function getBreakpointFromElement(current?: HTMLElement | null) {
  const width = current?.clientWidth || -1;

  const breakpointsArray = [];

  for (let breakpointsKey in breakpoints) {
    breakpointsArray.push({
      key: breakpointsKey,
      value: breakpoints[breakpointsKey],
    });
  }

  const sortedBreakpoints = breakpointsArray.sort((a, b) => a.value - b.value);

  let breakpoint: string | undefined = undefined;

  sortedBreakpoints.forEach(value => {
    if (width > value.value) breakpoint = value.key;
  });
  return breakpoint;
}
