import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { authService } from '@/services';
import { useCookies } from 'react-cookie';

export default function Auth0Page() {
  const { loginWithRedirect, getAccessTokenSilently } = useAuth0();
  // eslint-disable-next-line
  const [_, setCookie] = useCookies();
  const navigate = useNavigate();

  useEffect(() => {
    authService.loginWithAuth0(getAccessTokenSilently, setCookie).then(
      value => {
        console.log('We have an auth0 token, redirecting');
        navigate('/dashboard');
      },
      reason => {
        loginWithRedirect({}).then(value => console.log(''));
      },
    );
    // eslint-disable-next-line
  }, []);
  return <>Loading</>;
}
