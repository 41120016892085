import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import { NewsWidget } from '@/widgets/news-widget';
import DashboardAddItemControl from '@/components/dashboard/dashboard-add-item-control';

export default function DashboardEditControls({
  dashboardName,
  onDashboardNameChange,
  onSaveDashboard,
  onAddWidget,
  activeWidgets,
}: {
  dashboardName: string;
  onDashboardNameChange: (s: string) => void;
  onSaveDashboard: () => void;
  onAddWidget: (w: string) => void;
  activeWidgets: NewsWidget[];
}) {
  return (
    <>
      <input
        type="text"
        className={'form-control form-control-sm me-2'}
        onChange={e => {
          e.preventDefault();
          e.stopPropagation();
          onDashboardNameChange(e.target.value);
        }}
        value={dashboardName}
      ></input>
      <span>
        <button
          style={{ fontFamily: 'Inter' }}
          className={'btn btn-outline-success me-2 btn-sm'}
          onClick={() => onSaveDashboard()}
        >
          <FontAwesomeIcon icon={faFloppyDisk} />
        </button>
      </span>
      <span className={'d-inline me-2'}>
        <DashboardAddItemControl onAddWidget={onAddWidget} activeWidgets={activeWidgets} />
      </span>
    </>
  );
}
