import { Page } from '@/model/page';
import { NewsEvent, NewsEventFactory } from '@/model/calendar/news-event';
import { DataRelease } from '@/model/calendar/data-release';
import { PageUtil } from '@/util/page-util';
import { DataSeries } from '@/model/calendar/data-series';
import { DataSeriesRelations } from '@/model/calendar/data-series-relation';
import { DataTopic } from '@/model/data/data-topic';
import { AuctionProduct } from '@/model/auctions/auction-product';
import { AuctionTopic } from '@/model/auctions/auction-topic';
import axios from 'axios';

import { Calendar, CalendarWindow } from '@/model/calendar/calendar';
import dayjs, { ManipulateType } from 'dayjs';
import { userForecastService } from '@/services';

let allDataReleases: DataRelease[];
let allAuctionProducts: AuctionProduct[];

let allDataReleasesById: { [key: number]: DataRelease } = {};
let allAuctionProductsByDisplay: { [key: string]: AuctionProduct } = {};
let allDataSeries: { [key: number]: DataSeries } = {};
let topicTree: DataTopic[] = [];
let allTopics: DataTopic[] = [];

async function instantiate(event: NewsEvent) {
  NewsEventFactory(event);

  if (!allDataReleases) await getAllReleases();
  if (!allAuctionProducts) await getAllAuctionProducts();

  event.dataRelease = allDataReleasesById[event.dataReleaseId || -1];
  event.auctionProduct = allAuctionProductsByDisplay[event.title || -1];

  for (let entry of event.dataSeriesEntries) {
    const userForecasts = await userForecastService.getForecastForSeriesEntry(
      event.date,
      entry.dataSeriesId,
    );

    if (userForecasts) entry.forecasts.push(userForecasts);

    const series = allDataSeries[entry.dataSeriesId];

    if (!series) continue;

    entry.hasProvider = series.providers && series.providers.length > 0;

    if (series && series.related) {
      for (let relation of series.related) {
        if (relation.relation === DataSeriesRelations.HAS_PREVIOUS_REVISED_BY) {
          for (let otherEntry of event.dataSeriesEntries) {
            if (otherEntry.dataSeriesId === relation.target) {
              entry.hasPreviousRevision = true;
              entry.previousRevisionEntry = otherEntry;
            }
          }
        } else if (relation.relation === DataSeriesRelations.IS_PREVIOUS_REVISION_OF) {
          entry.isPreviousRevision = true;
        }
      }
    }
  }
}

async function getCalendarPage(start: number, end: number, page = 0): Promise<Page<NewsEvent>> {
  const result = (
    await axios.get(
      `/api/select/calendar/events?start=${start}&end=${end}&page=${page}&includeCoverage=true&includeInstantAnswers=true&includeAuctions=true`,
    )
  ).data;

  for (let event of result.content) {
    await instantiate(event);
  }

  return result;
}

async function getCalendar(start: number, end: number): Promise<NewsEvent[]> {
  return PageUtil.getAllPages<NewsEvent>(currentPage => {
    return getCalendarPage(start, end, currentPage);
  });
}

async function getAllCalendars(): Promise<Calendar[]> {
  return PageUtil.getAllPages<Calendar>(currentPage => {
    return axios.get(`/api/select/calendars?page=${currentPage}`).then(value => value.data);
  });
}

async function getCalendarBySlug(slug: string): Promise<Calendar> {
  return axios.get(`/api/select/calendars/by-slug/${slug}`).then(value => value.data as Calendar);
}

async function getEventsFromCalendarWithWindow(
  id: string | number,
  window: CalendarWindow,
): Promise<NewsEvent[]> {
  let unit: ManipulateType = 'weeks';
  let value = 1;

  switch (window) {
    case 'QUARTERLY':
      unit = 'month';
      value = 3;
      break;
    case 'WEEKLY':
      unit = 'week';
      break;
    case 'YEARLY':
      unit = 'year';
      break;
    case 'MONTHLY':
      unit = 'month';
  }

  const start = dayjs();
  const end = dayjs().add(value, unit);

  return PageUtil.getAllPages<NewsEvent>(currentPage => {
    return axios
      .get(
        `/api/select/calendars/${id}/events?page=${currentPage}&start=${start.valueOf()}&end=${end.valueOf()}`,
      )
      .then(value => value.data);
  });
}

async function getEvent(id: string): Promise<NewsEvent> {
  const event = (await axios.get(`/api/select/calendar/events/${id}`)).data;

  await instantiate(event);

  return event;
}

async function getReleases(page: number, size: number): Promise<Page<DataRelease>> {
  return (await axios.get(`/api/select/release?page=${page}&pageSize=${size}`)).data;
}

async function getAuctionProducts(page: number, size: number): Promise<Page<AuctionProduct>> {
  return (await axios.get(`/api/select/auction-product?page=${page}&pageSize=${size}`)).data;
}

async function getAllReleases(): Promise<DataRelease[]> {
  if (!allDataReleases) {
    const topics: { [key: string]: DataTopic } = {};

    allDataReleases = await PageUtil.getAllPages<DataRelease>(currentPage => {
      return getReleases(currentPage, 100);
    });

    allDataReleases.forEach(release => {
      release.series.forEach(series => {
        allDataSeries[series.id] = series;
        series.topics &&
          series.topics.forEach(value => {
            topics[value.qcode] = value;
          });
      });

      allDataReleasesById[release.id] = release;
    });

    for (const v of Object.values(topics)) {
      if (v.broader && topics[v.broader]) {
        v.broaderTopic = topics[v.broader];

        if (!topics[v.broader].children) topics[v.broader].children = [];

        v.broaderTopic.children.push(v);
      } else {
        if (!topicTree.find(value => value.qcode === v.qcode)) topicTree.push(v);
      }
      allTopics.push(v);
    }
  }

  return Promise.resolve(allDataReleases);
}

async function getAllAuctionProducts(): Promise<AuctionProduct[]> {
  if (!allAuctionProducts) {
    const topics: { [key: string]: AuctionTopic } = {};

    allAuctionProducts = await PageUtil.getAllPages<AuctionProduct>(currentPage => {
      return getAuctionProducts(currentPage, 100);
    });

    allAuctionProducts.forEach(auctionProduct => {
      auctionProduct.type.topics.forEach(value => {
        topics[value.qcode] = value;
      });

      allAuctionProductsByDisplay[auctionProduct.display] = auctionProduct;
    });

    for (const v of Object.values(topics)) {
      if (v.broader && topics[v.broader]) {
        v.broaderTopic = topics[v.broader];

        if (!topics[v.broader].children) topics[v.broader].children = [];

        v.broaderTopic.children.push(v);
      } else {
        if (!topicTree.find(value => value.qcode === v.qcode)) topicTree.push(v);
      }
      allTopics.push(v);
    }
  }

  return Promise.resolve(allAuctionProducts);
}

async function getTopicTree(): Promise<DataTopic[]> {
  await getAllReleases();
  await getAllAuctionProducts();
  return topicTree;
}

const selectApi = {
  getReleases,
  getAllReleases,
  getAllAuctionProducts,
  getAuctionProducts,
  getCalendar,
  getCalendarPage,
  getEvent,
  getTopicTree,
  getAllCalendars,
  getEventsFromCalendarWithWindow,
  getCalendarBySlug,
};

export default selectApi;
