export default function Podcast({
  podcastId,
  light = false,
}: {
  podcastId: string;
  light?: boolean;
}) {
  return (
    <iframe
      title="Podcast iframe"
      src={`https://playlist.megaphone.fm/?p=${podcastId}&artwork=false&sharing=false&light=${light}`}
      width="100%"
      height="482"
    ></iframe>
  );
}
