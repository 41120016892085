import { Button, Card, Col, Modal, Row } from 'react-bootstrap';
import { useState } from 'react';

import './dashboard-create-modal.css';
import suggestedLayouts from './suggested-layouts';
import { Layouts } from 'react-grid-layout';
import { MNIDashboardWidgetPreferences } from '@/model/preferences/myMNIPreferences';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function DashboardCreateModal({
  showCreateDashboardModal,
  setShowCreateDashboardModal,
  onCreate,
}: {
  showCreateDashboardModal: boolean;
  setShowCreateDashboardModal: (b: boolean) => void;
  onCreate: (s: string, layouts?: Layouts, preferences?: MNIDashboardWidgetPreferences) => void;
}) {
  const [localDashboardName, setLocalDashboardName] = useState<string>('');

  const [selectedTemplate, setSelectedTemplate] = useState<number>();

  return (
    <Modal
      show={showCreateDashboardModal}
      size="lg"
      onHide={() => {
        setLocalDashboardName('');
        setSelectedTemplate(undefined);
        setShowCreateDashboardModal(false);
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="p-3">
        <h2 className="text-center">Create dashboard</h2>
        <h4 className="text-center">Create from template</h4>
        <Row
          xs={1}
          md={2}
          lg={suggestedLayouts.length === 3 || suggestedLayouts.length > 3 ? 3 : 2}
          className="g-4 my-2"
        >
          {suggestedLayouts
            .sort((a, b) => a.order - b.order)
            .map((sl, i) => (
              <Col key={i}>
                <Card
                  className={`suggestion-card h-100 ${selectedTemplate === i ? 'selected' : ''}`}
                  style={{
                    textAlign: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={() => onCreate(sl.name, sl.layouts, sl.widgetPreferences)} //setSelectedTemplate(selectedTemplate === i ? undefined : i)}
                >
                  <Card.Body>
                    <Card.Title style={{ fontWeight: 'bolder', fontSize: '15px' }}>
                      <h1>
                        <FontAwesomeIcon icon={sl.icon} />
                      </h1>
                      {sl.name}
                    </Card.Title>
                    <Card.Text style={{ fontWeight: 'normal', fontSize: '13px' }}>
                      {sl.description}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
        </Row>

        {selectedTemplate === undefined && (
          <>
            <div className="divider-with-text">
              <hr className="line" />
              <span className="divider-text">OR</span>
              <hr className="line" />
            </div>
            <h4 className="text-center">Create custom</h4>
            <div className="text-center">
              <input
                className="w-75 my-3"
                type="text"
                onChange={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  setLocalDashboardName(e.target.value);
                }}
                placeholder="Dashboard name"
                value={localDashboardName}
              ></input>
            </div>
          </>
        )}
        <Modal.Footer>
          <Button
            style={{ height: '40px', fontFamily: 'Inter' }}
            className="btn btn-warning"
            onClick={() => {
              setLocalDashboardName('');
              setSelectedTemplate(undefined);
              setShowCreateDashboardModal(false);
            }}
          >
            Cancel
          </Button>

          <Button
            style={{ height: '40px', fontFamily: 'Inter' }}
            className="btn btn-success"
            onClick={() => {
              if (selectedTemplate === undefined && !localDashboardName) {
                return;
              }

              if (selectedTemplate !== undefined) {
                const selectedLayout = suggestedLayouts[selectedTemplate];
                onCreate(
                  selectedLayout.name,
                  selectedLayout.layouts,
                  selectedLayout.widgetPreferences,
                );
              } else {
                onCreate(localDashboardName);
              }
              setSelectedTemplate(undefined);
            }}
          >
            Create
          </Button>
        </Modal.Footer>
      </div>
    </Modal>
  );
}
