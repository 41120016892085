import { WidgetHost } from '@/widgets/widget-host';
import { WidgetHostContext } from '@/widgets/widget-host/widget-host-context';
import useWidgetPreferences from '@/widgets/widget-preferences/use-widget-preferences';
import { useContext, useEffect, useState } from 'react';

export default function useWidgetState<StateType, PreferencesType>(
  widgetId: string,
  initialState?: StateType,
  defaultPreferences?: PreferencesType,
) {
  // Initialize state with optional initial values
  const [state, setState] = useState<StateType | undefined>(initialState);
  const [preferences, setPreferences] = useState<PreferencesType | undefined>(defaultPreferences);

  const { saveWidgetPreferences, getWidgetPreferences } = useWidgetPreferences<PreferencesType>();
  const { hostId, currentViewId } = useContext<WidgetHost>(WidgetHostContext);

  // Define an update function with explicit typing for TypeScript compatibility
  const updateWidgetState = (newState: Partial<StateType>) => {
    setState(
      prevState =>
        ({
          ...(prevState || {}), // Keep previous state
          ...newState,
        }) as StateType,
    );
  };

  const setWidgetState = (state: StateType) => {
    setState(state);
  };

  const updateWidgetPreferences = (newState: PreferencesType) => {
    console.log('Updating widget preferences', newState, widgetId);
    setPreferences(prevState => {
      const updated = {
        ...(prevState || {}), // Keep previous state
        ...newState,
      };

      saveWidgetPreferences(updated, widgetId).then(value => console.log('Saved widget state'));

      return updated;
    });
  };

  const setWidgetPreferences = (state: PreferencesType) => {
    console.log('Saving widget preferences', state, widgetId);
    setPreferences(state);
    saveWidgetPreferences(state, widgetId).then(value => console.log('Saved widget state'));
  };

  useEffect(() => {
    setPreferences(defaultPreferences);

    getWidgetPreferences(widgetId)
      .then(
        preferences => {
          if (preferences) setPreferences(preferences);
        },
        () => console.log('failed to initialize preferences'),
      )
      .then(() => {});
    // eslint-disable-next-line
  }, [hostId, currentViewId, widgetId]);

  return {
    state,
    preferences,
    setWidgetState,
    updateWidgetState,
    setWidgetPreferences,
    updateWidgetPreferences,
  };
}
