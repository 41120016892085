import { faVolumeHigh, faVolumeXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonGroup, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';

export default function DashboardNotifications({
  notificationSoundOn,
  setNotificationSoundOn,
}: {
  notificationSoundOn: boolean;
  setNotificationSoundOn: (on: boolean) => void;
}) {
  return (
    <span>
      <ButtonGroup>
        <ToggleButtonGroup value={notificationSoundOn ? ['on'] : []} type={'checkbox'}>
          <ToggleButton
            size={'sm'}
            id="Dashboard notifications sound"
            variant={notificationSoundOn ? 'success' : 'warning'}
            value={'on'}
            onClick={event => setNotificationSoundOn(!notificationSoundOn)}
          >
            {notificationSoundOn && <FontAwesomeIcon icon={faVolumeHigh} />}
            {!notificationSoundOn && <FontAwesomeIcon icon={faVolumeXmark} />}
          </ToggleButton>
        </ToggleButtonGroup>
      </ButtonGroup>
    </span>
  );
}
