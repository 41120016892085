import { useState } from 'react';
import styles from './dashboard-drawer.module.css';
import { faBookmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BookmarkList from '@/components/bookmarks/bookmark-list';

export default function DashboardDrawer() {
  const [sidePanelOpen, setSidePanelOpen] = useState(false);

  return (
    <div className={`position-relative  h-100 `}>
      <div
        className={`position-absolute end-0 h-100 border-start ${styles.dashboardDrawer} ${sidePanelOpen ? styles.dashboardDrawerOpen : styles.dashboardDrawerClosed}`}
      >
        <div
          className={` position-absolute d-inline  p-1 small text-center border-top border-start border-bottom border-2 ${styles.dashboardDrawerTab}`}
          onClick={event => setSidePanelOpen(!sidePanelOpen)}
        >
          <div className={'d-flex flex-column justify-content-around h-100'}>
            <div className={'d-flex flex-column'}>
              {
                <span>
                  <FontAwesomeIcon icon={faBookmark} />
                </span>
              }
            </div>
          </div>
        </div>

        <div
          className={`h-100 overflow-auto p-2 ${sidePanelOpen ? '' : 'd-none'} d-flex flex-column ${styles.dashboardDrawerContent}`}
        >
          <div className={'h-100 overflow-auto'}>
            <BookmarkList />
          </div>
        </div>
      </div>
    </div>
  );
}
